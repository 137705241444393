import React from 'react';
import PropTypes from 'prop-types';
import { Row, Button } from 'antd';
import MarkdownText from './components/markdown-text';

/**
 * MessageAction - The actions you can take on an message
 */
const MessageActions = ({ text, actions, actionHandler, attributes, template }) => {
  const onClickHandler = (event, action) => {
    actionHandler({
      type: 'MessageActions',
      event,
      values: {
        template,
        attributes,
        action,
      },
    });
  };

  return (
    <div>
      <MarkdownText text={text} />
      <Row type="flex" justify="center" align="middle">
        {actions.map((action) => (
          <Button
            id={action.id}
            type={action.type}
            block={action.block}
            onClick={(event) => onClickHandler(event, action)}
            style={{ margin: 4 }}
          >
            {action.text}
          </Button>
        ))}
      </Row>
    </div>
  );
};

MessageActions.propTypes = {
  /** The text for the form input */
  text: PropTypes.string,
  /** A list of actions */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.oneOf(['default', 'primary', 'ghost', 'dashed', 'danger', 'link']),
      text: PropTypes.string,
    }),
  ).isRequired,
  /**
   * Handler for the action that pass the object {type, event, value} as the param
   * @param {string} type
   * @param {DomEvent} event
   * @param {object} value
   */
  actionHandler: PropTypes.func.isRequired,
  /** attributes */
  attributes: PropTypes.object.isRequired,
  /** Message template */
  template: PropTypes.string.isRequired,
};

MessageActions.defaultProps = {
  text: '',
};

export default MessageActions;
