import React from 'react';

/**
 * MessageSystem - Render component, should be used together with the Message component
 *
 */
const MessageSystem = () => {
  return <div />;
};

export default MessageSystem;
