/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import classNames from 'classnames';
import MessageWrapper from './components/message-wrapper';
import MessageAvatar from './components/message-avatar';
import * as utils from './utils';
import useStyles from './message-styles';

/**
 * Message - A high level component which implements all the logic required for a message
 */
const Message = ({
  isMine,
  message,
  groupStyle,
  onMessageEvent,
  isRenderInteractionUI,
  measure,
}) => {
  const [author, setAuthor] = useState(undefined);
  const classes = useStyles();

  useEffect(() => {
    const initMessage = async () => {
      try {
        const member = await message.getMember();
        if (!member) return;
        const user = await member.getUser();
        if (!user) return;
        setAuthor(user);
      } catch (ex) {
        return;
      }
    };
    if (!author) {
      initMessage();
    }
  }, []);

  useEffect(() => {
    if (!measure) {
      return;
    }
    measure();
  }, [isRenderInteractionUI]);

  const type = useMemo(() => utils.getMessageType(message), [message]);

  if (type === 'system') {
    return <div />;
  }
  const { attributes } = author || {};
  const { name = '', avatar = '' } = attributes || {};
  return (
    <div className={classNames(classes.message, classes[groupStyle])}>
      {isMine ? (
        <div className={classes.layoutEnd}>
          <MessageWrapper
            groupStyle={groupStyle}
            isMine
            isRenderInteractionUI={isRenderInteractionUI}
            onMessageEvent={onMessageEvent}
            onComplete={measure}
            message={message}
          />
          <MessageAvatar groupStyle={groupStyle} name={name} image={avatar} align="topRight" />
        </div>
      ) : (
        <div className={classes.layoutStart}>
          <MessageAvatar groupStyle={groupStyle} name={name} image={avatar} align="topLeft" />
          <MessageWrapper
            groupStyle={groupStyle}
            isMine={false}
            isRenderInteractionUI={isRenderInteractionUI}
            onMessageEvent={onMessageEvent}
            onComplete={measure}
            message={message}
          />
        </div>
      )}
    </div>
  );
};

Message.propTypes = {
  /** The message object */
  message: PropTypes.object.isRequired,
  /** Indicate the message created by the user */
  isMine: PropTypes.bool.isRequired,
  /** A style to apply to this message. ie. top, bottom, single */
  groupStyle: PropTypes.string.isRequired,
  /** Is render interaction UI */
  isRenderInteractionUI: PropTypes.bool.isRequired,
  /** measure should be called when cell content is ready to be measured */
  measure: PropTypes.func.isRequired,
  /** Fires when the user clicks a quick action button */
  onMessageEvent: PropTypes.func,
};

Message.defaultProps = {
  onMessageEvent: undefined,
};

export default React.memo(Message);
