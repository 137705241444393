import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import { List } from 'react-virtualized';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  emptyContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '@global': {
    '.ReactVirtualized__List:focus': {
      outline: 'none',
    },
  },
});

const VList = ({
  width,
  height,
  rowCount,
  rowHeight,
  rowRenderer,
  onRowsRendered,
  onScroll,
  scrollToAlignment,
  scrollToIndex,
  deferredMeasurementCache,
  registerChild,
}) => {
  const classes = useStyles();

  const noRowsRenderer = () => (
    <div className={classes.emptyContainer}>
      <Empty />
    </div>
  );

  return (
    <List
      ref={registerChild}
      width={width}
      height={height}
      rowCount={rowCount}
      rowHeight={rowHeight}
      rowRenderer={rowRenderer}
      noRowsRenderer={noRowsRenderer}
      onRowsRendered={onRowsRendered}
      onScroll={onScroll}
      scrollToIndex={scrollToIndex}
      scrollToAlignment={scrollToAlignment}
      overscanRowCount={10}
      deferredMeasurementCache={deferredMeasurementCache}
    />
  );
};

VList.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
  rowRenderer: PropTypes.func.isRequired,
  onRowsRendered: PropTypes.func,
  onScroll: PropTypes.func,
  scrollToIndex: PropTypes.number,
  deferredMeasurementCache: PropTypes.object,
};

VList.defaultProps = {
  onRowsRendered: undefined,
  onScroll: undefined,
  scrollToIndex: undefined,
  deferredMeasurementCache: undefined,
};

export default VList;
