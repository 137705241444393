import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown/with-html';
import gemoji from 'remark-gemoji-to-emoji';

/**
 * Text - Render component, should be used inside the Message component
 *
 */
const MarkdownText = ({ text }) => (
  <ReactMarkdown source={text} escapeHtml={false} plugins={[[gemoji]]} />
);

MarkdownText.propTypes = {
  /**
   * The Markdown or html source to parse
   */
  text: PropTypes.string,
};

MarkdownText.defaultProps = {
  text: undefined,
};

export default React.memo(MarkdownText);
