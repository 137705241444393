import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Row, Avatar, Typography } from 'antd';

const useStyles = createUseStyles({
  avatar: {
    marginRight: 24,
  },
  title: {
    marginRight: 12,
    maxWidth: 300,
  },
});

const { Title } = Typography;
const ChannelInfo = ({ friendlyName }) => {
  const classes = useStyles();
  return (
    <Row type="flex" align="middle">
      <Avatar className={classes.avatar}>{friendlyName ? friendlyName.charAt(0) : 'U'}</Avatar>
      <Row type="flex" align="middle">
        {friendlyName ? (
          <Title className={classes.title} style={{ marginBottom: 0 }} level={4} ellipsis>
            {friendlyName}
          </Title>
        ) : (
          ''
        )}
      </Row>
    </Row>
  );
};

ChannelInfo.propTypes = {
  /** The current channel name */
  friendlyName: PropTypes.string.isRequired,
};

export default ChannelInfo;
