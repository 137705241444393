import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button, InputNumber, Select, Checkbox } from 'antd';
import BigNumber from 'bignumber.js';
import MarkdownText from './components/markdown-text';

/**
 * MessageForm - Render a input form, should be used together with the Message component
 *
 */
const MessageForm = ({ form, text, inputs, actions, actionHandler, attributes, template }) => {
  const { getFieldDecorator, isFieldTouched, getFieldError, getFieldsError } = form;

  useEffect(() => {
    form.validateFields((err) => {
      if (err) {
        console.info('MessageForm', err);
      }
    });
  }, []);

  const onClickHandler = (event, action) => {
    event.preventDefault();
    if (action.type === 'primary') {
      form.validateFields((err, values) => {
        if (!err) {
          actionHandler({
            type: 'MessageForm',
            event,
            values: {
              template,
              attributes,
              action,
              fields: {
                ...values,
              },
            },
          });
        } else {
          console.info('MessageForm', err);
        }
      });
    } else {
      actionHandler({
        type: 'MessageForm',
        event,
        values: {
          template,
          attributes,
          action,
        },
      });
    }
  };

  const stepValidator = (rule, value, callback) => {
    const currentValue = new BigNumber(value);
    if (currentValue.isNaN()) {
      callback('Please input a valid number');
    }

    const { from, step } = rule;
    const originalValue = new BigNumber(from);
    const isStepValid = currentValue
      .minus(originalValue)
      .modulo(step)
      .isEqualTo(0);
    if (!isStepValid) {
      callback(`Please input a value with step of ${step} from ${from}`);
    } else {
      callback();
    }
  };

  const rangeValidator = (rule, value, callback) => {
    if (value > rule.max) {
      callback(`Please enter a value less than ${rule.max}`);
    } else if (value < rule.min) {
      callback(`Please enter a value greater than ${rule.min}`);
    } else {
      callback();
    }
  };

  const hasErrors = (fieldsError) => {
    console.log(('MessageForm hasErrors', fieldsError));
    Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  const getInputInfo = (label, input, isTouched, error) => {
    const isDisable = input && input.disabled;
    if (isDisable) {
      return {
        validateStatus: 'validating',
        help: `Sorry, ${label} is not negotiable`,
      };
    }
    if (isTouched && error) {
      return {
        validateStatus: 'error',
        help: error,
      };
    }
    return {
      validateStatus: '',
      help: '',
    };
  };

  return (
    <div>
      <MarkdownText text={text} />
      <Form layout="horizontal" labelAlign="right">
        {inputs.map((input) => {
          const { id, type, label, layout, validator, inputProps, options } = input;
          const inputInfo = getInputInfo(label, inputProps, isFieldTouched(id), getFieldError(id));
          let inputComp = null;
          switch (type) {
            case 'InputNumber':
              inputComp = (
                <InputNumber
                  {...inputProps}
                  style={{
                    width: '100%',
                  }}
                />
              );
              break;
            case 'Select':
              inputComp = (
                <Select {...inputProps}>
                  {options.map((option) => {
                    return <Select.Option value={option.value}>{option.text}</Select.Option>;
                  })}
                </Select>
              );
              break;
            case 'Checkbox':
              inputComp = <Checkbox {...inputProps} />;
              break;
            default:
              inputComp = <div>{`This ${type} of input component is not support`}</div>;
              break;
          }
          const validators = validator.map((rule) => {
            switch (rule.type) {
              case 'step':
                return {
                  ...rule,
                  validator: stepValidator,
                };
              case 'range':
                return {
                  ...rule,
                  validator: rangeValidator,
                };
              default:
                return rule;
            }
          });

          return (
            <Form.Item
              label={label}
              {...layout}
              validateStatus={inputInfo.validateStatus}
              help={inputInfo.help}
            >
              <Row type="flex">
                {getFieldDecorator(id, {
                  initialValue: inputProps.initialValue,
                  valuePropName: inputProps.valuePropName ? inputProps.valuePropName : 'value',
                  validateFirst: true,
                  rules: validators,
                })(inputComp)}
              </Row>
            </Form.Item>
          );
        })}
        <Row type="flex" justify="center" align="middle" style={{ marginBottom: 4 }}>
          {actions.map((action) => (
            <Button
              id={action.id}
              type={action.type}
              block={action.block}
              onClick={(event) => onClickHandler(event, action)}
              disabled={action.type === 'primary' ? hasErrors(getFieldsError()) : false}
              style={{ margin: 4 }}
            >
              {action.text}
            </Button>
          ))}
        </Row>
      </Form>
    </div>
  );
};

MessageForm.propTypes = {
  /** The Markdown or html source to parse */
  text: PropTypes.string.isRequired,
  /** A list of inputs */
  inputs: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** A list of actions */
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Handler for actions */
  actionHandler: PropTypes.func.isRequired,
  /** Message template */
  template: PropTypes.string.isRequired,
  /** Form provided by ant form */
  form: PropTypes.object.isRequired,
  /** Attributes */
  attributes: PropTypes.object.isRequired,
};
const WrappedMessageForm = Form.create({ name: 'form' })(MessageForm);

export default WrappedMessageForm;
