/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Chat } from '@negobot/nego-chat-react';
import { LoadingOutlined } from '@ant-design/icons';
import Negobot from '@negobot/nego-chat-sdk';
import { Spin } from 'antd';
import qs from 'qs';
import { useLocation } from 'dva';
import { Layout, MainContent, SiderContent } from './components';
import * as logRocket from '../../utils/logrocket';
import useStyles from './styles';
import * as config from '../../configs';

const Home = () => {
  const classes = useStyles();
  const [client, setClient] = useState(undefined);
  const [loading, setLoading] = useState({ isLoading: true, tip: '' });
  const [initial, setInitial] = useState(null);
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const {
    api_key,
    tenant_id,
    tenant_name,
    tenant_type,
    customer_id,
    customer_name,
    product_id,
  } = params;

  const onInitExtraParams = (event) => {
    if (event.data) {
      try {
        const data = JSON.parse(event.data);
        logRocket.identify(customer_id, { api_key, data });
        setInitial(data);
      } catch (ex) {
        // console.log('onInitExtraParams error', ex);
      }
    }
  };

  useEffect(() => {
    const initSdk = async () => {
      // const serviceUrl = config.;
      const apiUrl = config.default.API_URL;
      const negobot = new Negobot(apiUrl);
      setLoading({
        isLoading: true,
        tip: 'Initializing the chat...',
      });
      const chat = await negobot.getChat({
        api_key,
        tenant_id,
        tenant_name,
        tenant_type,
        customer_id,
        customer_name,
        product_id,
      });
      if (chat) {
        setClient(chat);
        setLoading({
          isLoading: false,
          tip: '',
        });
      } else {
        setClient(null);
        setLoading({
          isLoading: true,
          tip: 'Fail to init the chat instance, please contact the negobot admin!',
        });
      }
    };
    const initExtraParams = () => {
      window.addEventListener('message', onInitExtraParams, false);
    };
    initSdk();
    initExtraParams();
  }, [api_key, tenant_id, tenant_name, tenant_type, customer_id, customer_name, product_id]);
  return (
    <div className={classes.root}>
      {loading.isLoading ? (
        <div className={classes.center}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip={loading.tip} />
        </div>
      ) : (
        <Chat
          className={classes.chat}
          client={client}
          initial={initial}
          render={({
            getMoreChannels,
            getSubscribedChannels,
            loadingChannels,
            channels,
            channelsPaginator,
            getChannelBySid,
            loadingChannel,
            channel,
            user,
          }) => {
            return (
              <Layout
                siderContent={SiderContent}
                mainContent={MainContent}
                getMoreChannels={getMoreChannels}
                getSubscribedChannels={getSubscribedChannels}
                loadingChannels={loadingChannels}
                channels={channels}
                channelsPaginator={channelsPaginator}
                getChannelBySid={getChannelBySid}
                loadingChannel={loadingChannel}
                channel={channel}
                user={user}
                initial={initial}
              />
            );
          }}
        />
      )}
    </div>
  );
};

export default Home;
