import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _remove from 'lodash/remove';

/**
 * A helper function that support to sort the channel in order to lastest on the top
 * @param {Channel} a
 * @param {Channel} b
 */
const sortChannel = (a, b) => {
  const aDate = new Date(a.lastMessage ? a.lastMessage.timestamp : a.dateCreated);
  const bDate = new Date(b.lastMessage ? b.lastMessage.timestamp : b.dateUpdated);
  return bDate - aDate;
};

/**
 * Chat - Wrapper component for Chat. The needs to be placed around any other chat components.
 * This Chat component provides the chat context to all other components.
 *
 * The chat context provides the following props:
 *
 * - client (the client connection)
 * - channels (the list of channels)
 * - setActiveChannel (a function to set the currently active channel)
 * - activeChannel (the currently active channel)
 *
 */
const Chat = ({ client, render, initial }) => {
  const [loadingChannels, setLoadingChannels] = useState(false);
  const [channels, setChannels] = useState([]);
  const [channelsPaginator, setChannelsPaginator] = useState(null);
  const [loadingChannel, setLoadingChannel] = useState(true);
  const [channel, setChannel] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const initChat = async () => {
      console.log('Chat initChat', client.user);
      try {
        const { user: initialUser } = initial || {};
        if (initialUser) {
          await client.user.updateAttributes(initialUser);
        }
        setUser(client.user);
        console.log('Chat initChat success', ex);
      } catch (ex) {
        console.log('Chat initChat fail', ex);
      }
    };
    const registerEvents = () => {
      console.log('Chat registerEvents');
      client.on('channelAdded', onChannelAdded);
      // client.on('channelInvited', onChannelInvited)
      // client.on('channelJoined', onChannelJoined)
      // client.on('channelLeft', onChannelLeft)
      client.on('channelRemoved', onChannelRemoved);
      // client.on('channelUpdated', onChannelUpdated)
      client.on('connectionError', onConnectionError);
      client.on('connectionStateChanged', onConnectionStateChanged);
      // client.on('memberJoined', onTypingEnded)
      // client.on('memberLeft', onTypingEnded)
      // client.on('memberUpdated', onTypingEnded)
      client.on('messageAdded', onMessageAdded);
      // client.on('messageRemoved', onTypingEnded)
      // client.on('messageUpdated', onTypingEnded)
      // client.on('pushNotification', onTypingEnded)
      // client.on('tokenAboutToExpire', onTypingEnded)
      // client.on('tokenExpired', onTypingEnded)
      // client.on('typingEnded', onTypingEnded)
      // client.on('typingStarted', onTypingEnded)
      // client.on('tokenExpired', onTypingEnded)
      // client.on('userSubscribed', onTypingEnded)
      // client.on('userUnsubscribed', onTypingEnded)
      // client.on('userUpdated', onTypingEnded)
    };
    registerEvents();
    initChat();
  }, [client]);

  const getSubscribedChannels = useCallback(async () => {
    console.log('Chat getSubscribedChannels');
    try {
      setLoadingChannels(true);
      const data = await client.getSubscribedChannels();
      setChannelsPaginator(data);
      const { items } = data;
      items.sort(sortChannel);
      setChannels(items);
      setLoadingChannels(false);
      console.log('Chat getSubscribedChannels success:', data.items);
    } catch (ex) {
      setChannels([]);
      setLoadingChannels(false);
      console.log('Chat getSubscribedChannels error', ex);
    }
  }, [client]);

  const getMoreChannels = useCallback(
    async (direction) => {
      console.info('Chat getMoreChannels', direction);
      if (loadingChannels) {
        return;
      }
      if (
        (!channelsPaginator && !channelsPaginator.hasPrevPage) ||
        (!channelsPaginator && !channelsPaginator.hasNextPage)
      ) {
        return;
      }
      setLoadingChannels(true);
      if (direction === 'backwards') {
        try {
          const pagination = await paginator.prevPage();
          setChannels((prev) => {
            const items = [...pagination.items, ...prev];
            items.sort(sortChannel);
            return items;
          });
          setChannelsPaginator(pagination);
        } catch (ex) {
          console.log('getMoreChannels error:', ex);
        }
      } else {
        try {
          const pagination = await paginator.nextPage();
          setChannels((prev) => {
            const items = [...prev, ...pagination.items];
            items.sort(sortChannel);
            return items;
          });
          setChannelsPaginator(pagination);
        } catch (ex) {
          console.log('getMoreChannels error:', ex);
        }
      }
      setLoadingChannels(false);
    },
    [channelsPaginator],
  );

  const getChannelBySid = useCallback(
    async (channelSid) => {
      console.log('Chat getChannelBySid');
      try {
        setLoadingChannel(true);
        const data = await client.getChannelBySid(channelSid);
        setChannel(data);
        setLoadingChannel(false);
        console.log('Chat getChannelBySid success:', data);
      } catch (ex) {
        setChannel(null);
        setLoadingChannel(false);
        console.log('Chat getChannelBySid error:', ex);
      }
    },
    [client],
  );

  const onChannelAdded = async (chn) => {
    console.log('Chat onChannelAdded:', chn);
    setChannels((prev) => {
      const items = [...prev, chn];
      items.sort(sortChannel);
      return items;
    });
  };

  const onChannelRemoved = (chn) => {
    console.log('Chat onChannelRemoved:', chn);
    setChannels((prev) => _remove(prev, (current) => current.sid !== channel.sid));
  };

  const onConnectionError = (terminal, message, httpStatusCode, errorCode) => {
    console.log('Chat onConnectionError', terminal, message, httpStatusCode, errorCode);
  };

  const onConnectionStateChanged = (connectionState) => {
    console.log('Chat onConnectionStateChanged', connectionState);
  };

  const onMessageAdded = (message) => {
    console.log('Chat onMessageAdded', message);
  };

  console.log('Chat render:', client);
  return render({
    getSubscribedChannels,
    loadingChannels,
    getMoreChannels,
    channels,
    channelsPaginator,
    getChannelBySid,
    loadingChannel,
    channel,
    user,
  });
};

Chat.propTypes = {
  /**
   * A Twilio Client object.
   */
  client: PropTypes.object.isRequired,
  /**
   * Using the render props to make the chat more flexible
   */
  render: PropTypes.func.isRequired,
  /**
   * Initial info for the chat instance
   */
  initial: PropTypes.object,
};

Chat.defaultProps = {
  initial: undefined,
};

export default React.memo(Chat);
