import React, { useState } from 'react';
import { List, Menu, Row, Button, Col } from 'antd';
import { createUseStyles } from 'react-jss';
import MarkdownText from './components/markdown-text';

const useStyles = createUseStyles({
  root: {
    '@global': {
      '.ant-menu-item': {
        backgroundColor: '#fff',
        height: 'inherit',
        lineHeight: 1.5,
        whiteSpace: 'normal',
        padding: '8px',
      },
    },
  },
});

const MessageSelect = ({ type, options, actions, actionHandler, attributes, template }) => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const classes = useStyles();
  const multiple = type === 'multiSelect';

  const onClickHandler = (event, action) => {
    if (action.type === 'primary') {
      actionHandler({
        type: 'MessageSelect',
        event,
        values: {
          template,
          attributes,
          action,
          selection: { selectedKeys },
        },
      });
    } else {
      actionHandler({
        type: 'MessageSelect',
        event,
        values: {
          template,
          attributes,
          action,
        },
      });
    }
  };
  const onSelect = ({ item, key, selectedKeys }) => {
    setSelectedKeys(selectedKeys);
  };

  const onDeselect = ({ item, key, selectedKeys }) => {
    setSelectedKeys(selectedKeys);
  };

  return (
    <div className={classes.root}>
      <Menu
        multiple={multiple}
        onSelect={onSelect}
        onDeselect={onDeselect}
        selectedKeys={selectedKeys}
        style={{ background: 'inherit' }}
      >
        {options.map(({ id, image, title, description }) => {
          return (
            <Menu.Item className={classes.menuItem} key={id}>
              <Row gutter={8}>
                <Col span={6}>
                  {image && <img alt={title} src={image} style={{ height: '100px' }} />}
                </Col>
                <Col span={18}>
                  <MarkdownText text={title} />
                  <MarkdownText text={description} />
                </Col>
              </Row>
            </Menu.Item>
          );
        })}
      </Menu>
      <Row type="flex" justify="center" align="middle" style={{ marginBottom: 4 }}>
        {actions.map((action) => (
          <Button
            id={action.id}
            type={action.type}
            block={action.block}
            onClick={(event) => onClickHandler(event, action)}
            style={{ margin: 4 }}
          >
            {action.text}
          </Button>
        ))}
      </Row>
    </div>
  );
};

export default MessageSelect;
