/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Spin, Icon } from 'antd';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const VLoading = () => {
  const classes = useStyles();
  const indicator = <Icon type="loading" spin />;
  return (
    <div className={classes.root}>
      <Spin indicator={indicator} tip="loading..." />
    </div>
  );
};

export default VLoading;
