/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { AutoSizer } from 'react-virtualized';
import VList from './v-list';

const VAutoSizer = ({
  defaultHeight,
  defaultWidth,
  disableHeight,
  disableWidth,
  ...otherProps
}) => (
  <AutoSizer
    style={{ overflow: 'auto', width: '100%', height: '100%' }}
    defaultHeight={defaultHeight}
    defaultWidth={defaultWidth}
    disableHeight={disableHeight}
    disableWidth={disableWidth}
  >
    {({ height, width }) => <VList height={height} width={width} {...otherProps} />}
  </AutoSizer>
);

export default VAutoSizer;
