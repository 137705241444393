/**
 * Util method that help to get the message type.
 * @param {object} message
 * @returns {string} type
 */
export const getMessageType = (message) => {
  const { body } = message;
  let parsedBody;
  try {
    parsedBody = JSON.parse(body);
  } catch {
    parsedBody = {
      type: 'default',
    };
  }
  const { type } = parsedBody;
  return type;
};
