import React, { useCallback, useEffect, useState } from 'react';

import { ChannelList } from '@negobot/nego-chat-react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import useStyles from './styles';

const SiderContent = ({
  getMoreChannels,
  channels,
  channelsPaginator,
  getSubscribedChannels,
  getChannelBySid,
  initial,
}) => {
  const classes = useStyles();
  const [selectedChannel, setSelectedChannel] = useState(undefined);

  useEffect(() => {
    const initChannelList = async () => {
      await getSubscribedChannels();
    };
    initChannelList();
  }, [getSubscribedChannels]);

  useEffect(() => {
    const initChannel = async () => {
      if (selectedChannel) return;
      const { defaultChannel } = initial || {};
      let defaultChannelId = defaultChannel && defaultChannel.channelId;
      if (channels && channels.length > 0 && !defaultChannel) {
        defaultChannelId = channels[0].sid;
      }
      if (defaultChannelId) {
        setSelectedChannel(defaultChannelId);
        await getChannelBySid(defaultChannelId);
      }
    };
    initChannel();
  }, [initial, channels, getChannelBySid, selectedChannel]);
  console.log('defaultChannelId', selectedChannel);
  const onSelectChannelHandler = useCallback(
    async (sid) => {
      setSelectedChannel(sid);
      await getChannelBySid(sid);
    },
    [getChannelBySid],
  );
  console.log('setSelectedChannel', selectedChannel);
  if (!channelsPaginator) {
    const indicator = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div className={classes.root}>
        <Spin indicator={indicator} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <ChannelList
        channels={channels}
        getMoreChannels={getMoreChannels}
        paginator={channelsPaginator}
        onSelectChannel={onSelectChannelHandler}
        selectedChannel={selectedChannel}
      />
    </div>
  );
};

export default React.memo(SiderContent);
