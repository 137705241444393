import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import VInfiniteLoader from '../v-list/v-infinite-loader';
import VCellMeasurer from '../v-list/v-cell-measurer';
import VCellMeasurerCache from '../v-list/v-cell-measurer-cache';
import VLoading from '../v-list/v-loading';
import Message from '../message';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
  },
});

const cache = new VCellMeasurerCache({
  fixedWidth: true,
});

const getGroupStyles = (messages) => {
  const { length } = messages;
  const messageGroupStyles = {};
  for (let i = 0; i < length; i += 1) {
    const previousMessage = messages[i - 1];
    const message = messages[i];
    const nextMessage = messages[i + 1];
    const isTopMessage = !previousMessage || message.author !== previousMessage.author;
    const isBottomMessage = !nextMessage || message.author !== nextMessage.author;

    let groupStyles;
    if (isTopMessage) {
      groupStyles = 'top';
    }
    if (isBottomMessage) {
      if (isTopMessage) {
        groupStyles = 'single';
      } else {
        groupStyles = 'bottom';
      }
    }
    if (!isTopMessage && !isBottomMessage) {
      groupStyles = 'middle';
    }
    messageGroupStyles[message.sid] = groupStyles;
  }
  return messageGroupStyles;
};

const isMine = (message, me) => message.author === me;

const MessageList = ({ me, messages, onMessageSend, paginator, getMoreMessages }) => {
  const [scrollToIndex, setScrollToIndex] = useState(0);
  const [tailMessageIndex, setTailMessageIndex] = useState(undefined);

  useEffect(() => {
    const newTailMessage = messages[messages.length - 1];
    if (tailMessageIndex && newTailMessage && newTailMessage.sid !== tailMessageIndex.sid) {
      setTailMessageIndex(newTailMessage);
      setScrollToIndex(messages.length - 1);
    } else {
      setTailMessageIndex(messages[messages.length - 1]);
      setScrollToIndex(paginator.items.length);
    }
  }, [paginator, messages]);

  const classes = useStyles();

  const groupStyles = useMemo(() => getGroupStyles(messages), [messages]);

  const rowRenderer = useCallback(
    ({ index, key, parent, style }) => {
      let realIndex = 0;
      if (paginator.hasPrevPage) {
        if (index === 0) {
          const renderLoading = (measure, registerChild) => (
            <div ref={registerChild} style={style}>
              <VLoading />
            </div>
          );
          return (
            <VCellMeasurer
              cache={cache}
              columnIndex={0}
              key={key}
              parent={parent}
              rowIndex={index}
              render={renderLoading}
            />
          );
        } else {
          realIndex = index - 1;
        }
      } else {
        realIndex = index;
      }
      const message = messages[realIndex];
      const groupStyle = groupStyles[message.sid];
      const renderMessage = (measure, registerChild) => {
        const isRenderInteractionUI = realIndex >= messages.length - 1 && !me.includes('admin-');
        return (
          <div ref={registerChild} style={style}>
            <Message
              isMine={isMine(message, me)}
              message={message}
              groupStyle={groupStyle}
              onMessageEvent={onMessageSend}
              isRenderInteractionUI={isRenderInteractionUI}
              measure={measure}
            />
          </div>
        );
      };

      return (
        <VCellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
          render={renderMessage}
        />
      );
    },
    [messages],
  );

  const isRowLoaded = useCallback(
    ({ index }) => {
      return index > 0 || !paginator.hasPrevPage;
    },
    [paginator],
  );

  const loadMoreRows = useCallback(() => {
    return getMoreMessages('backwards');
  }, [getMoreMessages]);

  const onScroll = useCallback(() => {
    setScrollToIndex(undefined);
  }, []);

  const rowCount = paginator.hasPrevPage ? messages.length + 1 : messages.length;

  return (
    <div className={classes.root}>
      <VInfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowHeight={cache.rowHeight}
        rowRenderer={rowRenderer}
        rowCount={rowCount}
        onScroll={onScroll}
        scrollToAlignment="start"
        scrollToIndex={scrollToIndex}
        deferredMeasurementCache={cache}
        threshold={1}
      />
    </div>
  );
};

MessageList.propTypes = {
  /** The user friendly name */
  me: PropTypes.string.isRequired,
  /** Paginator is use to load the chat history */
  paginator: PropTypes.object.isRequired,
  /** The list of message in a Channel. */
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** The callback to call for more message */
  getMoreMessages: PropTypes.func.isRequired,
  /** Fires when the user types a message and clicks the Send button or presses Enter. */
  onMessageSend: PropTypes.func.isRequired,
};

export default MessageList;
