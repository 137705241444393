import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import VInfiniteLoader from '../v-list/v-infinite-loader';
import VCellMeasurer from '../v-list/v-cell-measurer';
import VCellMeasurerCache from '../v-list/v-cell-measurer-cache';
import VLoading from '../v-list/v-loading';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  channelItem: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    cursor: 'pointer',
    padding: [0, 20],
    '&:hover': {
      backgroundColor: '#e6f7ff',
      color: '#1890ff',
    },
  },
  channelItemSelected: {
    backgroundColor: '#e6f7ff',
    color: '#1890ff',
  },
});

const cache = new VCellMeasurerCache({
  defaultWidth: 40,
  fixedWidth: true,
  defaultHeight: 40,
  fixedHeight: true,
});

const ChannelItem = ({ data, onSelect, selected }) => {
  const classes = useStyles();
  const { sid, friendlyName } = data;

  const onClick = () => {
    if (!onSelect) return;
    onSelect(sid);
  };

  return (
    <div
      className={classNames(classes.channelItem, { [classes.channelItemSelected]: selected })}
      onClick={onClick}
    >
      {friendlyName}
    </div>
  );
};

/**
 *  ChannelList - A preview list of channels, allowing you to select the channel you want to open
 */
const ChannelList = ({
  channels,
  getMoreChannels,
  paginator,
  onSelectChannel,
  selectedChannel,
}) => {
  const [scrollToIndex, setScrollToIndex] = useState(0);
  const classes = useStyles();

  const rowRenderer = useCallback(
    ({ index, key, parent, style }) => {
      const channel = channels[index];
      if (!channel) {
        const renderLoading = (measure, registerChild) => (
          <div ref={registerChild} style={style}>
            <VLoading ref={registerChild} />
          </div>
        );
        return (
          <VCellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
            render={renderLoading}
          />
        );
      }
      const renderChannel = (measure, registerChild) => {
        return (
          <div ref={registerChild} style={style}>
            <ChannelItem
              data={channel}
              onSelect={onSelectChannel}
              selected={selectedChannel === channel.sid}
            />
          </div>
        );
      };

      return (
        <VCellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
          render={renderChannel}
        />
      );
    },
    [channels, selectedChannel],
  );

  const isRowLoaded = useCallback(
    ({ index }) => {
      return index < channels.length - 1 || !paginator.hasNextPage;
    },
    [paginator],
  );

  const loadMoreRows = useCallback(() => {
    return getMoreChannels('forward');
  }, [getMoreChannels]);

  const onScroll = useCallback(() => {
    setScrollToIndex(undefined);
  }, []);

  const rowCount = paginator.hasNextPage ? channels.length + 1 : channels.length;

  return (
    <div className={classes.root}>
      <VInfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowHeight={cache.rowHeight}
        rowRenderer={rowRenderer}
        rowCount={rowCount}
        onScroll={onScroll}
        scrollToAlignment="end"
        scrollToIndex={scrollToIndex}
        deferredMeasurementCache={cache}
        threshold={1}
      />
    </div>
  );
};

ChannelList.propTypes = {
  /** List of all subscribed Channels */
  channels: PropTypes.arrayOf(PropTypes.object),
  /** Paginator is use to load the chat history */
  paginator: PropTypes.object.isRequired,
  /** The callback to call for more message */
  getMoreChannels: PropTypes.func.isRequired,
  /** Fire when use select a channel the call back function with parameter is a DOM event */
  onSelectChannel: PropTypes.func,
  /** Set selected channel */
  selectedChannel: PropTypes.string,
};

ChannelList.defaultProps = {
  channels: [],
  onSelectChannel: undefined,
  selectedChannel: undefined,
};

export default ChannelList;
