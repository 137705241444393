import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

export const identify = (id, data) => {
  LogRocket.identify(id, data);
};

export const reduxMiddleware = () => {
  return LogRocket.reduxMiddleware();
};

LogRocket.init('oeqwex/nego-iframe-buyer');
LogRocket.getSessionURL((sessionURL) => {
  Sentry.configureScope((scope) => {
    scope.setExtra('sessionURL', sessionURL);
  });
});
