import React from 'react';
import { CellMeasurer } from 'react-virtualized';

const VCellMeasurer = ({ render, cache, key, columnIndex, parent, rowIndex }) => (
  <CellMeasurer
    key={key}
    cache={cache}
    columnIndex={columnIndex}
    parent={parent}
    rowIndex={rowIndex}
  >
    {({ measure, registerChild }) => render(measure, registerChild)}
  </CellMeasurer>
);

export default VCellMeasurer;
