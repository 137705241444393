import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InfiniteLoader } from 'react-virtualized';
import VAutoSizer from './v-autosizer';

const VInfiniteLoader = ({
  isRowLoaded,
  loadMoreRows,
  rowCount,
  minimumBatchSize,
  threshold,
  ...otherProps
}) => {
  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={rowCount}
      threshold={threshold}
      minimumBatchSize={minimumBatchSize}
    >
      {({ onRowsRendered, registerChild }) => (
        <VAutoSizer
          onRowsRendered={onRowsRendered}
          rowCount={rowCount}
          registerChild={registerChild}
          {...otherProps}
        />
      )}
    </InfiniteLoader>
  );
};

VInfiniteLoader.propTypes = {
  isRowLoaded: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  threshold: PropTypes.number,
  minimumBatchSize: PropTypes.number,
};

VInfiniteLoader.defaultProps = {
  minimumBatchSize: 10,
  threshold: 15,
};

export default VInfiniteLoader;
