import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Avatar, Tooltip } from 'antd';
import useStyles from '../message-styles';

const MessageAvatar = ({ name, image, groupStyle, align }) => {
  const classes = useStyles();
  const isShowAvatar = !['single', 'bottom'].includes(groupStyle);
  return (
    <div
      className={classNames(classes.avatar, {
        [classes.hideAvatar]: isShowAvatar,
      })}
    >
      <Tooltip placement={align} title={name}>
        <Avatar src={image}>{name.substr(0, 1)}</Avatar>
      </Tooltip>
    </div>
  );
};

MessageAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  groupStyle: PropTypes.string.isRequired,
};

export default React.memo(MessageAvatar);
