import { Layout as AntdLayout, Spin } from 'antd';
import { ChannelInfo, MessageInput, MessageList } from '@negobot/nego-chat-react';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import useStyles from './styles';

const ChannelInfoWrapper = ({ friendlyName }) => {
  const classes = useStyles();
  return (
    <div className={classes.channelInfoContainer}>
      <div className="channelInfo">
        <ChannelInfo friendlyName={friendlyName} />
      </div>
    </div>
  );
};

const MessageListWrapper = ({
  user,
  messages,
  getMessages,
  sendMessage,
  getMoreMessages,
  paginator,
}) => {
  useEffect(() => {
    const initMessageList = async () => {
      await getMessages();
    };
    initMessageList();
  }, []);

  if (!paginator) {
    const indicator = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <Spin indicator={indicator} />;
  }

  return (
    <MessageList
      me={user.identity}
      messages={messages}
      getMoreMessages={getMoreMessages}
      onMessageSend={sendMessage}
      paginator={paginator}
    />
  );
};

const MessageInputWrapper = ({ sendMessage }) => {
  return <MessageInput handleSubmit={sendMessage} />;
};

const { Header, Content, Footer } = AntdLayout;
const Layout = ({
  sid,
  friendlyName,
  loadingMessages,
  getMessages,
  messages,
  sendMessage,
  getMoreMessages,
  paginator,
  user,
  initial,
}) => {
  const classes = useStyles();
  useEffect(() => {
    const sendInitMesage = async () => {
      if (initial) {
        await sendMessage({
          type: 'MessageSystem',
          event: null,
          values: {
            intentName: 'restart',
            text: 'Restart',
          },
        });
        await sendMessage({
          type: 'MessageCustom',
          event: null,
          values: {
            intentName: 'request_nego',
            fields: {
              ...initial,
            },
            text: initial.text,
          },
        });
      }
    };
    sendInitMesage();
  }, [initial]);
  return (
    <AntdLayout className={classes.root}>
      <Header>
        <ChannelInfoWrapper sid={sid} friendlyName={friendlyName} />
      </Header>
      <Content>
        <MessageListWrapper
          user={user}
          loadingMessages={loadingMessages}
          messages={messages}
          getMessages={getMessages}
          sendMessage={sendMessage}
          getMoreMessages={getMoreMessages}
          paginator={paginator}
        />
      </Content>
      <Footer>
        <MessageInputWrapper sendMessage={sendMessage} />
      </Footer>
    </AntdLayout>
  );
};

export default Layout;
