import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';

const flatten = (obj) => {
  if (!obj) return undefined;
  const separator = '.';
  const iterator = (source, prefix, flattened) => {
    const keys = Object.keys(source);

    for (let n = 0; n < keys.length; n += 1) {
      const key = keys[n];
      const val = source[key];

      if (!_isObject(val)) {
        // eslint-disable-next-line no-param-reassign
        flattened[prefix + key] = val;
      } else {
        iterator(val, prefix + key + separator, flattened);
      }
    }
  };

  const result = {};
  iterator(obj, '', result);
  return result;
};

const format = (string, data) =>
  string.replace(/\{[0-9a-zA-Z_\\.\\-]+\}/g, (match, i) => {
    console.log('match:', match, 'atIndex:', i, 'data:', data);
    const key = match.substring(1, match.length - 1);
    console.log('key:', key);
    return data[key] || 'undefined';
  });

/**
 * Function to parse the UI event into the rasa format message
 * @param {DomEvent} event
 * @param {object} values
 * {
      template: 'String',
      attributes: {
        slots: 'Object'
      },
      action:{
        id: 'String',
        value: 'Object',
        type: 'Enum',
        text: 'String',
        block: 'Boolean'
      }
    }
  @returns {object}
  {
    messageAttributes {
      annotation: /intentName{"key":"value"}
    },
    message: {
      type: 'simple',
      text: message,
    }
  }
 */
const handleActions = (event, values) => {
  const { template, attributes, action } = values || {};
  const message = template
    ? format(template, {
        ...attributes.slots,
        ...flatten(action.value),
      })
    : action.text;
  const intentName = action.id || '';
  const entityValues = _isEmpty(action.value) ? '' : JSON.stringify(action.value);
  const annotationStr = `/${intentName}${entityValues}`;
  return {
    messageAttributes: {
      annotation: annotationStr,
    },
    message: {
      type: 'simple',
      text: message,
    },
  };
};

/**
 * Function to parse the UI event into the rasa format message
 * @param {DomEvent} event
 * @param {object} values
 * {
      template: 'String',
      attributes: {
        slots: 'Object'
      },
      fields: 'Object',
      action:{
        id: 'String',
        value: 'Object',
        type: 'Enum',
        text: 'String',
        block: 'Boolean'
      }
    }
  @returns {object}
  {
    messageAttributes {
      annotation: /intentName{"key":"value"}
    },
    message: {
      type: 'simple',
      text: message,
    }
  }
 */
const handleForm = (event, values) => {
  const { template, attributes, action, fields } = values || {};
  const message = template
    ? format(values.template, {
        ...attributes.slots,
        ...flatten(fields),
        ...flatten(action.value),
      })
    : action.text;
  const intentName = action.id || '';
  const entityValues = _isEmpty(fields) ? '' : JSON.stringify(fields);
  const annotationStr = `/${intentName}${entityValues}`;
  return {
    messageAttributes: {
      annotation: annotationStr,
    },
    message: {
      type: 'simple',
      text: message,
    },
  };
};

/**
 *
 * @param {DomEvent} event
 * @param {object} values
 * {
 *  text: 'string'
 * }
 */
const handleInput = (event, values) => {
  const { text } = values;
  return {
    messageAttributes: {
      annotation: '/userInputMessage',
    },
    message: {
      type: 'simple',
      text,
    },
  };
};

/**
 * Function to parse the UI event into the rasa format message
 * @param {DomEvent} event
 * @param {object} values
 * {
      template,
      attributes,
      action:{
        id: 'action_1',
        value: 'action_1',
        type: 'primary',
        text: 'ACCEPT',
        display: 'optional'
      }
    }
  @returns {object}
  {
    messageAttributes {
      annotation: /intentName
    },
    message: {
      type: 'simple',
      text: message,
    }
  }
 */
const handleCarousel = (event, values) => {
  const { template, attributes, action } = values || {};
  const message = template
    ? format(values.template, {
        ...attributes.slots,
        ...flatten(action.value),
      })
    : action.text;
  const intentName = action.id || '';
  const entityValues = _isEmpty(action.value) ? '' : JSON.stringify(action.value);
  const annotationStr = `/${intentName}${entityValues}`;
  return {
    messageAttributes: {
      annotation: annotationStr,
    },
    message: {
      type: 'simple',
      text: message,
    },
  };
};

/**
 * Function to parse the UI event into the rasa format message
 * @param {DomEvent} event
 * @param {object} values
 * {
      template: 'String',
      attributes: {
        slots: 'Object'
      },
      fields: 'Object',
      action:{
        id: 'String',
        value: 'Object',
        type: 'Enum',
        text: 'String',
        block: 'Boolean'
      }
    }
  @returns {object}
  {
    messageAttributes {
      annotation: /intentName{"key":"value"}
    },
    message: {
      type: 'simple',
      text: message,
    }
  }
 */
const handleSelect = (event, values) => {
  const { template, attributes, action, selection } = values || {};
  const message = template
    ? format(values.template, {
        ...attributes.slots,
        ...flatten(action.value),
      })
    : action.text;
  const intentName = action.id || '';
  const entityValues = _isEmpty(selection) ? '' : JSON.stringify(selection);
  const annotationStr = `/${intentName}${entityValues}`;
  return {
    messageAttributes: {
      annotation: annotationStr,
    },
    message: {
      type: 'simple',
      text: message,
    },
  };
};

/**
 *
 * @param {object} values
 * {
      intentName,
      fields: {
        key: value,
      },
      text: 'text'
    }
  @returns {object}
  {
    messageAttributes {
      annotation: /intentName{"key": "value"}
    },
    message: {
      type: 'system',
      text: message,
    }
  }
 */
const handleSystem = (event, values) => {
  const { intentName, fields, text } = values;
  const entityValues = _isEmpty(fields) ? '' : JSON.stringify(fields);
  const annotationStr = `/${intentName}${entityValues}`;
  return {
    messageAttributes: {
      annotation: annotationStr,
    },
    message: {
      type: 'system',
      text,
    },
  };
};

/**
 *
 * @param {object} values
 * {
      intentName,
      fields: {
        key: value,
      },
      text: 'text'
    }
  @returns {object}
  {
    messageAttributes {
      annotation: /intentName{"key": "value"}
    },
    message: {
      type: 'simple',
      text: message,
    }
  }
 */
const handleCustom = (event, values) => {
  const { intentName, fields, text } = values;
  const entityValues = _isEmpty(fields) ? '' : JSON.stringify(fields);
  const annotationStr = `/${intentName}${entityValues}`;
  return {
    messageAttributes: {
      annotation: annotationStr,
    },
    message: {
      type: 'simple',
      text,
    },
  };
};

/**
 *
  @returns {object}
  {
    messageAttributes: {
      annotation: '/unsupportedFormat',
    },
    message: {
      type: 'simple',
      text: 'This message is not supported',
    },
  }
 */
const handleDefault = (event, values) => {
  return {
    messageAttributes: {
      annotation: '/unsupportedFormat',
    },
    message: {
      type: 'simple',
      text: 'This message is not supported',
    },
  };
};

export {
  handleActions,
  handleForm,
  handleInput,
  handleCarousel,
  handleSystem,
  handleSelect,
  handleCustom,
  handleDefault,
};
